var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "h-screen flex w-full bg-img vx-row no-gutter items-center justify-center",
      attrs: { id: "page-login" }
    },
    [
      _c(
        "div",
        {
          staticClass: "vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4"
        },
        [
          _c("vx-card", [
            _c(
              "div",
              {
                staticClass: "full-page-bg-color",
                attrs: { slot: "no-body" },
                slot: "no-body"
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "vx-row no-gutter justify-center items-center"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "vx-col hidden lg:block lg:w-1/2" },
                      [
                        _c("img", {
                          staticClass: "mx-auto",
                          attrs: {
                            src: require("@/assets/images/pages/login.png"),
                            alt: "login"
                          }
                        })
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg"
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "p-8" },
                          [
                            _c(
                              "div",
                              { staticClass: "text-right" },
                              [
                                _c(
                                  "vs-dropdown",
                                  {
                                    staticClass: "cursor-pointer",
                                    attrs: {
                                      "vs-custom-content": "",
                                      "vs-trigger-click": ""
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "cursor-pointer flex i18n-locale"
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "h-5 w-7",
                                          attrs: {
                                            src: require("@/assets/images/flags/" +
                                              _vm.$i18n.locale +
                                              ".png"),
                                            alt: _vm.$i18n.locale
                                          }
                                        })
                                      ]
                                    ),
                                    _c(
                                      "vs-dropdown-menu",
                                      {
                                        staticClass:
                                          "w-48 i18n-dropdown vx-navbar-dropdown"
                                      },
                                      [
                                        _c(
                                          "vs-dropdown-item",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.updateLocale("en")
                                              }
                                            }
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "h-4 w-5 mr-1",
                                              attrs: {
                                                src: require("@/assets/images/flags/en.png"),
                                                alt: "en"
                                              }
                                            }),
                                            _vm._v(
                                              "\n                       English\n                    "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "vs-dropdown-item",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.updateLocale("es")
                                              }
                                            }
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "h-4 w-5 mr-1",
                                              attrs: {
                                                src: require("@/assets/images/flags/es.png"),
                                                alt: "es"
                                              }
                                            }),
                                            _vm._v(
                                              "\n                       Español\n                    "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c("div", { staticClass: "vx-card__title mb-8" }, [
                              _c("h4", { staticClass: "mb-4" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t(_vm.resources.Login.i18n) ||
                                        _vm.resources.Login.name
                                    ) +
                                    "\n                "
                                )
                              ]),
                              _c("p", [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t(_vm.alerts.MsgWelcome.i18n) ||
                                        _vm.alerts.MsgWelcome.name
                                    ) +
                                    ".\n                "
                                )
                              ])
                            ]),
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required|email|min:3",
                                  expression: "'required|email|min:3'"
                                }
                              ],
                              staticClass: "w-full no-icon-border",
                              attrs: {
                                "data-vv-validate-on": "blur",
                                name: "email",
                                icon: "icon icon-user",
                                "icon-pack": "feather",
                                "label-placeholder":
                                  _vm.$t(_vm.resources.Email.i18n) ||
                                  _vm.resources.Email.name
                              },
                              model: {
                                value: _vm.email,
                                callback: function($$v) {
                                  _vm.email = $$v
                                },
                                expression: "email"
                              }
                            }),
                            _c("span", { staticClass: "text-danger text-sm" }, [
                              _vm._v(_vm._s(_vm.errors.first("email")))
                            ]),
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required|min:4|max:30",
                                  expression: "'required|min:4|max:30'"
                                }
                              ],
                              staticClass: "w-full mt-6 no-icon-border",
                              attrs: {
                                "data-vv-validate-on": "blur",
                                type: "password",
                                name: "password",
                                icon: "icon icon-lock",
                                "icon-pack": "feather",
                                "label-placeholder":
                                  _vm.$t(_vm.resources.Password.i18n) ||
                                  _vm.resources.Password.name
                              },
                              model: {
                                value: _vm.password,
                                callback: function($$v) {
                                  _vm.password = $$v
                                },
                                expression: "password"
                              }
                            }),
                            _c("span", { staticClass: "text-danger text-sm" }, [
                              _vm._v(_vm._s(_vm.errors.first("password")))
                            ]),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex flex-wrap justify-between my-5"
                              },
                              [
                                _c(
                                  "router-link",
                                  { attrs: { to: "/pages/forgot-password" } },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$t(
                                            _vm.resources.ForgotPassword.i18n
                                          ) || _vm.resources.ForgotPassword.name
                                        ) +
                                        "\n                "
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "flex flex-wrap float-right my-5"
                              },
                              [
                                _c(
                                  "vs-button",
                                  {
                                    attrs: { disabled: !_vm.validateForm },
                                    on: { click: _vm.login }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(_vm.resources.Login.i18n) ||
                                          _vm.resources.Login.name
                                      )
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ]
                )
              ]
            )
          ])
        ],
        1
      ),
      _c(
        "vs-prompt",
        {
          attrs: { active: _vm.activePrompt },
          on: {
            cancel: function($event) {
              _vm.itaCode = ""
            },
            accept: _vm.getContact,
            "update:active": function($event) {
              _vm.activePrompt = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "con-exemple-prompt" },
            [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.resources.DistributorID.i18n) ||
                      _vm.resources.DistributorID.name
                  )
                )
              ]),
              _c("vs-input", {
                staticClass: "mt-3 w-full",
                attrs: { type: "number" },
                model: {
                  value: _vm.itaCode,
                  callback: function($$v) {
                    _vm.itaCode = $$v
                  },
                  expression: "itaCode"
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }