<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div
    class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center"
    id="page-login"
  >
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col hidden lg:block lg:w-1/2">
              <img
                src="@/assets/images/pages/login.png"
                alt="login"
                class="mx-auto"
              />
            </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
              <div class="p-8">
                <div class="text-right">
                  <vs-dropdown
                    vs-custom-content
                    vs-trigger-click
                    class="cursor-pointer"
                  >
                    <span class="cursor-pointer flex i18n-locale">
                      <img
                        class="h-5 w-7"
                        :src="
                          require(`@/assets/images/flags/${$i18n.locale}.png`)
                        "
                        :alt="$i18n.locale"
                      />
                    </span>
                    <vs-dropdown-menu
                      class="w-48 i18n-dropdown vx-navbar-dropdown"
                    >
                      <vs-dropdown-item @click="updateLocale('en')">
                        <img
                          class="h-4 w-5 mr-1"
                          src="@/assets/images/flags/en.png"
                          alt="en"
                        />
                        &nbsp;English
                      </vs-dropdown-item>
                      <vs-dropdown-item @click="updateLocale('es')">
                        <img
                          class="h-4 w-5 mr-1"
                          src="@/assets/images/flags/es.png"
                          alt="es"
                        />
                        &nbsp;Español
                      </vs-dropdown-item>
                    </vs-dropdown-menu>
                  </vs-dropdown>
                </div>

                <div class="vx-card__title mb-8">
                  <h4 class="mb-4">
                    {{ $t(resources.Login.i18n) || resources.Login.name }}
                  </h4>
                  <p>
                    {{ $t(alerts.MsgWelcome.i18n) || alerts.MsgWelcome.name }}.
                  </p>
                </div>
                <vs-input
                  v-validate="'required|email|min:3'"
                  data-vv-validate-on="blur"
                  name="email"
                  icon="icon icon-user"
                  icon-pack="feather"
                  :label-placeholder="
                    $t(resources.Email.i18n) || resources.Email.name
                  "
                  v-model="email"
                  class="w-full no-icon-border"
                />
                <span class="text-danger text-sm">{{
                  errors.first("email")
                }}</span>

                <vs-input
                  data-vv-validate-on="blur"
                  v-validate="'required|min:4|max:30'"
                  type="password"
                  name="password"
                  icon="icon icon-lock"
                  icon-pack="feather"
                  :label-placeholder="
                    $t(resources.Password.i18n) || resources.Password.name
                  "
                  v-model="password"
                  class="w-full mt-6 no-icon-border"
                />
                <span class="text-danger text-sm">{{
                  errors.first("password")
                }}</span>
                <div class="flex flex-wrap justify-between my-5">
                  <router-link to="/pages/forgot-password">
                    {{
                      $t(resources.ForgotPassword.i18n) ||
                      resources.ForgotPassword.name
                    }}
                  </router-link>
                </div>
                <div class="flex flex-wrap float-right my-5">
                  <vs-button :disabled="!validateForm" @click="login">{{
                    $t(resources.Login.i18n) || resources.Login.name
                  }}</vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
    <vs-prompt
      @cancel="itaCode = ''"
      @accept="getContact"
      :active.sync="activePrompt"
    >
      <div class="con-exemple-prompt">
        <span>{{
          $t(resources.DistributorID.i18n) || resources.DistributorID.name
        }}</span>
        <vs-input type="number" v-model="itaCode" class="mt-3 w-full" />
      </div>
    </vs-prompt>
  </div>
</template>

<script>
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import axios from "axios";
import VueJwtDecode from "vue-jwt-decode";
import utilities from "@/assets/resources/utilities.json";
import database from "@/assets/resources/enums/database.json";

export default {
  data() {
    return {
      database: database,
      email: "",
      password: "",
      resources: resources,
      alerts: alerts,
      status: null,
      utilities: utilities,
      itaCode: "",
      contactId: "",
      activePrompt: false,
      dataSignIn: "",
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.email != "" && this.password != "";
    },
  },
  methods: {
    updateLocale(locale) {
      this.$i18n.locale = locale;
      localStorage.setItem("language", locale);
    },

    async login() {
      this.$vs.loading();
      let _data = {
        email: this.email,
        password: this.password,
        language: this.$i18n.locale,
        ApplicationId: this.utilities.application.OV,
        deviceId: localStorage.getItem('__mmapiwsid') ? localStorage.getItem('__mmapiwsid') : 'no deviceId'
      };

      let listEmails = [
        "a.gomez@visiontravel.net",
        "odalis@visiontravel.net",
        "felipe@visiontravel.net",
        "j.castrillon@visiontravelmail.net",
        "a.lopez@visiontravelmail.net",
        "a.holguin@visiontravelmail.net",
        "j.suarez@visiontravelmail.net",
        "j.villegas@visiontravel.net",
        "a.ramirez@visiontravel.net",
        "v.hernandez@visiontravelmail.net",
        "d.lopez@visiontravelmail.net",
        "contactosarairoa@gmail.com"
      ];
      let isAllow = listEmails.filter((x) => x == this.email.toLowerCase());
      if (isAllow.length > 0) _data["itaCode"] = 1;

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_OVAPI}users/authenticate`,
        data: _data,
        headers: { "content-type": "application/json" },
      })
        .then(async (result) => {
          if (
            (result.data.contactType == this.database.contactType.Ita ||
              result.data.contactType == this.database.contactType.ClientIta) &&
            result.data.itaCode != ""
          ) {
            this.dataSignIn = result.data;
            this.itaCode = result.data.itaCode;
            this.contactId = result.data.id;
            await this.getContact();
            await this.setAccessInformation();           
            this.$vs.loading.close();
            this.$router.push("/");
          } else if (result.data.contactType === 2) {
            this.dataSignIn = result.data;
            if (isAllow.length > 0) this.activePrompt = true;

            this.$vs.loading.close();
          } else if (result.data.contactType === 3) {
            this.$vs.loading.close();
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgUserNotExist"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-alert-circle",
            });
          } else {
            this.$vs.loading.close();
            this.$vs.notify({
              time: 6000,
              title: this.$i18n.t("Alert"),
              text:
                result.data.signin[0] +
                "<br/>" +
                this.$i18n.t("MgsRestorePassword"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-alert-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        });
    },

    async getContact() {
      this.$vs.loading();

      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Ita/${this.$i18n.locale.toUpperCase()}/${this.itaCode}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${this.dataSignIn.tokenVT}`,
        },
      }).then(
        async (result) => {
          if (result.data) {
            this.dataSignIn.id = result.data.conctactId;
            localStorage.setItem("status", result.data.statusId);
            await this.setAccessInformation();
            let string = JSON.stringify({
              id: result.data.conctactId,
              affiliationDate: result.data.affiliationDate,
              contactType: result.data.contactType,
              name: result.data.name,
              lastName: result.data.lastName,
              email: result.data.email,
              photoURL: result.data.imageUrl,
              gender: result.data.gender,
              itaCode: this.itaCode,
              countryOperation: result.data.countryOperationDefault.countryCode,
              countryOperationName:
                result.data.countryOperationDefault.countryName,
              country: result.data.countryId,
              countryName: result.data.countryName,
              countryOperationEntry: result.data.countryOperationEntry,
              countryOperationNameEntry: result.data.countryOperationNameEntry,
              state: result.data.stateId,
              cityId: result.data.city,
              address: result.data.address != null ? result.data.address : "",
              birthDate: result.data.birthDate,
              phone: result.data.phone,
              documentNumber: result.data.document,
              stateName: result.data.stateName,
              cityName: result.data.cityName,
              renewalDate:
                result.data.renewalDate == "" || result.data.renewalDate == null
                  ? localStorage.getItem("renewalDate")
                  : result.data.renewalDate,
              language: result.data.language.toLowerCase(),
            });
            localStorage.setItem("userInfo", string);
            this.$vs.loading.close();
            this.$router.push("/");
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgUserNotExist"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-alert-circle",
            });
          }
          this.$vs.loading.close();
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("MsgError"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
          this.$vs.loading.close();
        }
      );
    },

    async setAccessInformation() {
      await this.haveNeo(this.dataSignIn.id);
      let decoded = VueJwtDecode.decode(this.dataSignIn.token);
      let decodedVT = VueJwtDecode.decode(this.dataSignIn.tokenVT);

      localStorage.setItem("tokenExpiry", decoded.exp);
      localStorage.setItem("tokenExpiryVT", decodedVT.exp);
      localStorage.setItem("loggedIn", this.dataSignIn.token);
      localStorage.setItem("tokenVT", this.dataSignIn.tokenVT);
      localStorage.setItem("tokenInfo", this.dataSignIn.infotoken);
      localStorage.setItem("periods", JSON.stringify(this.dataSignIn.periods));
      localStorage.setItem("labels", JSON.stringify(this.dataSignIn.labels));
      await this.validate();
    },

    async haveNeo(contactId) {
      let res = await axios.get(
        `${process.env.VUE_APP_OVAPI}Purchase/haveNeo/${contactId}`
      );
      localStorage.setItem("haveNeo", res.data[0].Count);
    },

    // async getAccessOffice(itaCode) {
    //   await axios.get(`${process.env.VUE_APP_OVAPI}getAccess/${itaCode}`).then(
    //     (result) => {
    //       if (result.data.recordset.length > 0) {
    //         if (result.data.recordset[0].Activo)
    //           localStorage.setItem("status", 1);
    //         else localStorage.setItem("status", 0);
    //       } else {
    //         localStorage.setItem("status", 2);
    //       }
    //     },
    //     (error) => {
    //       this.$vs.notify({
    //         title: this.$i18n.t("Error"),
    //         text: this.$i18n.t("MsgError") + "<br/>" + error.message,
    //         color: "danger",
    //         iconPack: "feather",
    //         position: "top-right",
    //         icon: "icon-x-circle",
    //       });
    //       localStorage.setItem("status", 0);
    //     }
    //   );
    // },

    async validate() {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_OVAPI}validate`,
        data: {
          infotoken: localStorage.getItem("tokenInfo"),
          distid: this.itaCode,
        },
        headers: {
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          if (result.data) {
            localStorage.setItem("renewalDate", result.data.Next_Renew_Date);
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("MsgError"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },
  },
};
</script>
